import React from "react"
import Layout from "../components/layout/Layout"
// import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import "../sass/pages/healthcarev2.scss"
import Faq from "../components/healthCareV2/faq/Faq"
import Map from "../components/healthCareV2/map/Map"
import Slider from "../components/healthCareV2/slider/Slider"
import Benefits from "../components/healthCareV2/benefits/Benefits"
import LpNav from "../components/healthCareV2/nav/Nav"
import Form from "../components/healthCareV2/form/Form"
import { StaticImage } from "gatsby-plugin-image"

import BackgroundImage from "gatsby-background-image"
import Opinions from "../components/healthCareV2/opinions/Opinions"
import Rating from "../components/healthCareV2/rating/Rating"
import { Helmet } from "react-helmet"
const HealthCareV2 = () => {
  // Set ImageData.
  // const imageData = bgImage
  const data = useStaticQuery(graphql`
    {
      bgImage: file(relativePath: { eq: "lp-healtcare-v2/hero_bg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageData = data.bgImage.childImageSharp.fluid
  return (
    <Layout className="lp-healcare-v2">
      <Helmet>
        <title>Pakiety medyczne dla Ciebie i bliskich</title>
        <meta
          name="description"
          content="Opieka medyczna dla Ciebie i bliskich z gwarantowaną dostępnością terminów i wizyt na już"
        ></meta>

        <meta
          name="og:description"
          content="Opieka medyczna dla Ciebie i bliskich z gwarantowaną dostępnością terminów i wizyt na już"
        ></meta>
        <meta
          name="twitter:description"
          content="Opieka medyczna dla Ciebie i bliskich z gwarantowaną dostępnością terminów i wizyt na już"
        ></meta>

        <meta
          name="twitter:title"
          content="Pakiety medyczne dla Ciebie i bliskich"
        ></meta>
        <meta
          name="og:title"
          content="Pakiety medyczne dla Ciebie i bliskich"
        ></meta>
      </Helmet>
      <LpNav />

      <BackgroundImage
        Tag="section"
        className="container-fluid lp-hero"
        fluid={imageData}
      >
        <div className="container hero-container">
          <div className="new">Nowość!</div>
          <div className="hashtag">#zdrowiej</div>
          <div className="hero-image-wrapper">
            <StaticImage
              className="hero-image"
              alt="people"
              loading="lazy"
              src="../images/lp-healtcare-v2/hero_people.png"
            />
          </div>
          <Form />
        </div>
      </BackgroundImage>

      <Benefits />
      <Opinions />
      <Slider />
      <Rating />
      <Map />
      <Faq />
    </Layout>
  )
}

export default HealthCareV2
