import React from "react"
import Heading from "../../heading/Heading"
import JSONData from "../../../../content/healthcare.json"
import FaqSingle from "./FaqSingle"
import './style.scss';

const Faq = () => {
  return (
    <section className="lp-faq bg-decoration ocf-yc" >
      <div id="faq" />
      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{__html: JSONData.faqHeading}}></h2>
        <div className="lp-faq__wrapper">
          {JSONData.faq.map((data, index) => (
            <FaqSingle key={`faq_item_${index}`} heading={data.title} content={data.content} />
          ))}
        </div>
      </div>
    </section>
  )
}


export default Faq
