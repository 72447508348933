
import "./style.scss";
import { Link } from "gatsby";
import React from "react"


const LpButton = ({className, href, text, isBlank=false})=>{
  return(

      <button className={className} >
      <Link  className=""  to={href} target={isBlank ? "_blank" : ""}>
      {text}
      </Link>
      </button>

  );
}
export default LpButton;