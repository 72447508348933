import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import LpButton from "../button/Button"
import JSONData from "../../../../content/healthcare.json"
import './style.scss';
import Image0 from "../../../images/lp-healtcare-v2/benefits/sms.svg";
import Image1 from "../../../images/lp-healtcare-v2/benefits/house.svg";
import Image2 from "../../../images/lp-healtcare-v2/benefits/calendar.svg";

const Benefits = () => {
  return (
    <section className="lp-benefits bg-decoration">
      <div id="dlaczego-enelmed"/>
      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{ __html: JSONData.benefitsHeading }}></h2>
        <div className="container">
          <ul className="lp-benefits-list">
            <li className="item-benefit">
              <Image0 />
              <p>Szybkie umawianie wizyt<br></br> w <strong>aplikacji w Twoim telefonie!</strong></p>
            </li>
            <li className="item-benefit">
              <Image1 />
              <p><strong>Butikowe przychodnie</strong><br></br> oraz dogodne lokalizacje</p>
            </li>
            <li className="item-benefit">
              <Image2 />
              <p>Dostępność terminów <br></br><strong>wizyt i badań na już!</strong></p>
            </li>
          </ul>

        </div>
        <div className="container container--small container--items-center">
          <LpButton href="#" className={"lp-btn lp-btn--ghost"} text="Wypełnij formularz" />
          <LpButton isBlank={true} href="https://sklep.enel.pl/wszystkie-pakiety/gid,112,producent,PAKIETY%20MEDYCZNE.html" className={"lp-btn "} text="Kup online" />
        </div>
      </div>
    </section>
  )
}


export default Benefits
