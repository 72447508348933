import React from "react"

import { Swiper, SwiperSlide } from 'swiper/react'
import { StaticImage } from "gatsby-plugin-image"
import LpButton from "../button/Button"
import JSONData from "../../../../content/healthcare.json"
import 'swiper/css'
import './style.scss';


const LpSlider = () => {
  return (
    <section className="lpSlider" >
      <div id="pakiety" />

      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{ __html: JSONData.sliderHeading }}></h2>


        <div className="container slider-container">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            loop={true}
            breakpoints={{
              // When window width is >= 768px
              512: {
                slidesPerView: 2,
                spaceBetween: 50
              },
              1024: {
                slidesPerView: 4,
              }
            }}

          >
            <SwiperSlide>
              <StaticImage
                alt="pakiety medyczne dla kobiet"
                loading="lazy"
                src="../../../images/lp-healtcare-v2/slider/women.png"
                width={418}
                height={393}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                alt="pakiety medyczne dla mężczyzn"
                loading="lazy"
                src="../../../images/lp-healtcare-v2/slider/men.png"
                width={417}
                height={393}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                alt="pakiety medyczne dla rodziny"
                loading="lazy"
                src="../../../images/lp-healtcare-v2/slider/family.png"
                width={417}
                height={393}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                alt="pakiety medyczne dla kobiet w ciąży"
                loading="lazy"
                src="../../../images/lp-healtcare-v2/slider/pregnant.png"
                width={417}
                height={393}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                alt="pakiety medyczne dla serniorów"
                loading="lazy"
                src="../../../images/lp-healtcare-v2/slider/senior.png"
                width={417}
                height={393}
              />
            </SwiperSlide>

          </Swiper>
        </div>
        <div className="container container--small container--items-center">
          <LpButton href="#" className={"lp-btn lp-btn--ghost"} text="Wypełnij formularz" />
          <LpButton isBlank={true} href="https://sklep.enel.pl/wszystkie-pakiety/gid,112,producent,PAKIETY%20MEDYCZNE.html" className={"lp-btn "} text="Kup online" />
        </div>
      </div>
    </section>
  )
}


export default LpSlider
