import React, { useState, useRef } from "react"
import Arrow from '../../../images/faq_ikona.svg'

const FaqSingle = ({ heading, content }) => {
  const [active, setActive] = useState(false);
  const hiddenRef = useRef();
  const handleClick = (e) => {
    if (typeof window !== "undefined") {
      const height = hiddenRef.current.getBoundingClientRect().height;
      const parent = hiddenRef.current.parentNode;
      console.log(parent);
      if (!active) {
        parent.style.maxHeight = height + 'px';
      }
      else {
        parent.style.maxHeight = 0 + 'px';
      }
      e.preventDefault();
      setActive(!active);
    }
  }

  return (
    <div className={`lp-faqSingle ${active ? 'lp-faqSingle--active' : ''}`} >
      <div className="lp-faqSingle__heading size--Big" onClick={(e => { handleClick(e); })}>
        <div className="arrow-wrapper"><Arrow /></div>
        <span dangerouslySetInnerHTML={{ __html: heading }}></span>
      </div>
      <div className="lp-faqSingle__content">
        <div className="lp-faqSingle__hidden" >
          <div dangerouslySetInnerHTML={{ __html: content }} ref={hiddenRef} />
        </div>
      </div>
    </div>
  )
}


export default FaqSingle
