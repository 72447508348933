import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import Spin from "../../spin/Spin"
import "./style.scss"

import { makeid } from "../../../ultis/functions"

const Form = () => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
  })

  const [sendingError, setSendingError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [marketingTel, toogleMarketingTel] = useState(false)
  const [showHiddenInputs, setShowHiddenInputs] = useState(true)

  const [marketingTelRequired, setMarketingTelRequired] = useState(false)
  // const [marketingSMSRequired, setMarketingSMSRequired] = useState(false);
  const [marketingRequired, setMarketingRequired] = useState(false)

  const marketingTelRef = useRef()
  const marketingTelCheckbox = useRef()

  const marketingTelField = register("marketingTel")

  const marketingTelFieldWatch = watch("tel", false) // you can supply default value as second argument


  const marketingTelFieldInput = register("tel", {
    required: true,
    pattern: {
      // value: /(\+)?(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}/i,
      value: /^(5[0-9]{8}|6[0-9]{8}|7[0-9]{8}|8[0-9]{8})$/,
      message: "Nieprawidłowy numer telefonu",
    },
  })



  const checkMarketingRequired = () => {
    if (
      marketingTelFieldWatch ||
      !marketingTelRequired
    ) {
      setMarketingRequired(false)
    } else {
      setMarketingRequired(true)
    }

    if (marketingTelCheckbox.current.checked) {
      setMarketingTelRequired(true)
    } else {
      setMarketingTelRequired(false)
    }
    console.log( marketingTelCheckbox.current.checked );
  }


  const onSubmit = (values, e) => {
    if (loading) return

    if (typeof window !== "undefined") {
      if (!marketingTelFieldWatch) {
        setMarketingRequired(true)
        return
      }
    }

    const formData = new FormData()
    if (typeof window !== "undefined") {
      const url_string = window.location.href.toLowerCase()
      const url = new URL(url_string)
      const urlOrgin = url.origin

      const traking = makeid(30)
      const campaningID = "40028";
      const utmMedium = url.searchParams.has("utm_medium")
        ? url.searchParams.get("utm_medium")
        : ""
      const utmSource = url.searchParams.has("utm_source")
        ? url.searchParams.get("utm_source")
        : ""
      const utmCampaing = url.searchParams.has("utm_campaign")
        ? url.searchParams.get("utm_campaign")
        : ""

      formData.append("KampaniaID", campaningID)
      formData.append("URL", urlOrgin)
      formData.append("Tracking", traking)
      formData.append("UTM_Medium", utmMedium)
      formData.append("UTM_Source", utmSource)
      formData.append("UTM_Campaign", utmCampaing)
      formData.append("Imie", values.name)
      formData.append("Nazwisko", values.surname)
      formData.append("Tel", values.tel)
      formData.append("ZgodaTel", marketingTelCheckbox.current.checked ? 1 : 0)
      formData.append("check2", values.check2)
      formData.append("check1", values.check1)
    }

    setLoading(true)
    const plainFormData = Object.fromEntries(formData.entries())
    const formDataJsonString = JSON.stringify(plainFormData)

    return fetch("/send/send_lp.php/", {
      method: "post",
      body: formDataJsonString,
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then(response => response.json())
      .then(response => {
        setLoading(false)
        if (response.error) {
          setSendingError(true)
        } else {
          if (typeof window !== "undefined") {
            // const dataLayer = window.dataLayer || []
            window.dataLayer.push({ transactionID: response.tracking })
          }
          navigate("/thank-you-page-v2/")
        }
      })
      .catch(error => {
        setLoading(false)
        setSendingError(true)
        console.error("Error:", error)
      })
  }

  const handleClick = (event, agrement) => {

    event.preventDefault()



    if (agrement === "marketingTel") {
      const height = marketingTelRef.current.getBoundingClientRect().height
      const parent = marketingTelRef.current.parentNode
      if (!marketingTel) {
        setMarketingTelRequired(true)
        parent.style.maxHeight = height + "px"
      } else {

        parent.style.maxHeight = 0 + "px"
      }
      toogleMarketingTel(!marketingTel)
    }
  }

  return (
    <>
      <form className="lp-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="lp-form__container">


          <h1 className="lp-form__heading"><strong>Nowe pakiety medyczne</strong> <br></br><strong>z gwarantowaną dostępnością terminów na już!</strong></h1>
          <p className="lp-form__desc">Zostaw kontakt, <strong>oddzwonimy w ciągu 24h!</strong></p>
          <label className="lp-form__input">
            <input
              {...register("name", {
                required: true,
                pattern: {
                  value:
                    /^[A-ZÀÁÂÄĄÇĆČĎÈÉÊËĘĚÍÎÏŁŃŇÑÓÔÖŘßŚŠŤÙÚÛÜŮÝŸŹŻŽa-zàáâäąçćčďèéêëęěíîïłńňñóôöřßśšťùúûüůýÿźżž]{2,}$/,
                  // value: /^[^0-9]{2,}$/,
                  message: "Podaj prawdziwe imię",
                },
              })}
              id="name"
              className="form_data"
              type="text"
              name="name"
              placeholder="Imię"
            />

            <div className="error">
              <span>
                {errors.name
                  ? errors.name.message
                    ? errors.name.message
                    : "Zapomiałeś o tym polu"
                  : ""}
              </span>
            </div>
          </label>

          <label className="lp-form__input">
            <input
              {...register("surname", {
                required: true,
                pattern: {
                  value: /^[A-ZÀÁÂÄĄÇĆČĎÈÉÊËĘĚÍÎÏŁŃŇÑÓÔÖŘßŚŠŤÙÚÛÜŮÝŸŹŻŽa-zàáâäąçćčďèéêëęěíîïłńňñóôöřßśšťùúûüůýÿźżž\s\-]{2,}$/i,
                  message: "Podaj prawdziwe nazwisko",
                },
              })}
              id="surname"
              className="form_data"
              type="text"
              name="surname"
              placeholder="Nazwisko"/>

            <div className="error">
              <span>
                {errors.surname
                  ? errors.surname.message
                    ? errors.surname.message
                    : "Zapomiałeś o tym polu"
                  : ""}
              </span>
            </div>
          </label>

          <label
            className={`lp-form__input--hidden ${showHiddenInputs ? "show" : ""}`}
          >
            <div className="lp-form__input ">
              <input
                {...marketingTelFieldInput}
                onChange={e => {
                  marketingTelFieldInput.onChange(e)
                  trigger(["tel"])
                }}
                className="form_data"
                type="tel"
                name="tel"
                id="tel"
                placeholder="Telefon"
              />

              <div className="error">
                <span>
                {errors.tel
                  ? errors.tel.message
                    ? errors.tel.message
                    : "Zapomiałeś o tym polu"
                  : ""}

                </span>
                  {/* <span>{errors.tel ? (errors.tel.message ? (errors.tel.message) : "Zapomiałeś o tym polu") : ''}</span> */}
              </div>
            </div>
          </label>

          <div className="agreement-container">


            <div className={`lp-form__agreement`}>
              <input
                {...marketingTelField}
                onChange={e => {
                  marketingTelField.onChange(e)
                  checkMarketingRequired()
                }}
                className="form_data"
                type="checkbox"
                id="marketingTel"
                name="marketingTel"
                value={true}
                ref={marketingTelCheckbox}
              />
              <label
                htmlFor="marketingTel"
                className={`agreement__label ${marketingTel ? "show" : "hide"}`}
              >
                <span>
                  Komunikacja marketingowa przez połączenia telefoniczne{" "}
                  <a
                    href="#"
                    onClick={e => {
                      handleClick(e, "marketingTel", "show")
                    }}
                    className="show"
                  >
                    Rozwiń
                  </a>{" "}
                  <a
                    href="#"
                    onClick={e => {
                      handleClick(e, "marketingTel", "hide")
                    }}
                    className="hide"
                  >
                    Zwiń
                  </a>
                </span>
              </label>
              <div className="agreement__content">
                <div className="agreement__wrapper" ref={marketingTelRef}>
                  <p>
                    Wyrażam zgodę na wykonywanie przez Centrum Medyczne ENEL-MED
                    S.A., z siedzibą w Warszawie przy ul. Słomińskiego 19 lok.
                    524, 00-195 Warszawa (CM ENEL-MED) połączeń telefonicznych
                    na podany w formularzu numer telefonu w celu przekazywania
                    informacji handlowo - marketingowych dotyczących CM
                    ENEL-MED, w tym informacji handlowo-marketingowych
                    dotyczących działalności CM ENEL-MED oraz oferowanych przez
                    CM ENEL-MED produktów i usług, również przy użyciu
                    automatycznych systemów wywołujących, co wiąże się z
                    używaniem telekomunikacyjnych urządzeń końcowych, których
                    jestem użytkownikiem.
                  </p>

                </div>
              </div>
            </div>

            <div className="lp-form__agreement">
              <div className="lp-form__rodo">
                <p>
                  Powyższe zgody są dobrowolne. Zgodę można wycofać
                  w&nbsp;każdym czasie bez wpływu na zgodność z&nbsp;prawem
                  przetwarzania dokonanego przed jej wycofaniem.
                </p>
                <p>
                  Uzupełnienie i&nbsp;wysłanie powyższego formularza oznaczają
                  zgodę na przedstawienie przez Centrum Medyczne ENEL-MED Spółka
                  Akcyjna z&nbsp;siedzibą w&nbsp;Warszawie przy ul. Słomińskiego
                  19 lok. 524, 00-195 Warszawa zamówionej oferty za
                  pośrednictwem podanego w&nbsp;formularzu środka komunikacji.
                  Zgodę można wycofać w&nbsp;każdym czasie bez wpływu na
                  zgodność z&nbsp;prawem przetwarzania dokonanego przed jej
                  wycofaniem.
                </p>
                <p>
                  Administratorem danych osobowych podanych w&nbsp;formularzu
                  jest Centrum Medyczne ENEL-MED Spółka Akcyjna z&nbsp;siedzibą
                  w&nbsp;Warszawie, ul. Słomińskiego 19 lok. 524, 00-195
                  Warszawa. Więcej informacji dotyczących przetwarzania danych
                  osobowych znajduje się w&nbsp;Polityce prywatności. Prosimy
                  o&nbsp;zapoznanie się z&nbsp;jej treścią.
                </p>
              </div>
            </div>
          </div>
          <div className="lp-form__button">
            <input
              className="hero__cap"
              {...register("check1")}
              type="hidden"
              name="check1"
              value="msm"
            />
            <input
              className="hero__cap"
              {...register("check2")}
              type="hidden"
              name="check2"
              value=""
            />
            <div className="button">
              <button
                type="submit"
                id="send_form"
                name="submit"
                className="btn btn-primary form-submit"
              >
                Wyślij
              </button>
            </div>
            {loading && <Spin />}
          </div>
          {sendingError && (
            <div className="error">
              Problem z&nbsp;zapisaniem danych. Spróbuj ponownie później
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export default Form
