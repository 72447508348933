import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper/core';

import JSONData from "../../../../content/healthcare.json"
import './style.scss';
// import 'swiper/css'
import 'swiper/css/pagination';
SwiperCore.use([Pagination]);

const Opinions = () => {
  return (
    <section className="opinions" >
      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{ __html: JSONData.opinionsHeading }}></h2>


        <div className="container slider-container">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
            // pagination={true}
            loop={true}
            breakpoints={{
              // When window width is >= 768px
              768: {
                slidesPerView: 2,
                spaceBetween: 150
              },

            }}

          >
          {JSONData.opinions.map((data, index) => (
            <SwiperSlide key={`opinion_item_${index}`} >
              <p>{data.text}</p><br></br>
              <p><b>{data.person}</b></p>
            </SwiperSlide>
          ))}

          </Swiper>
        </div>
      </div>
    </section>
  )
}


export default Opinions
