import React from "react"
import JSONData from "../../../../content/healthcare.json"
import './style.scss';
import SvgMap from "../../../images/lp-healtcare-v2/map.svg"

import SvgLi0 from "../../../images/lp-healtcare-v2/map_li_0.svg"
import SvgLi1 from "../../../images/lp-healtcare-v2/map_li_1.svg"
import SvgLi2 from "../../../images/lp-healtcare-v2/map_li_2.svg"
import SvgLi3 from "../../../images/lp-healtcare-v2/map_li_3.svg"
import SvgLi4 from "../../../images/lp-healtcare-v2/map_li_4.svg"

const Map = () => {
  return (
    <section className="lp-map" >
      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{__html: JSONData.mapHeading}}></h2>
        <p className="sectionDescription" dangerouslySetInnerHTML={{__html: JSONData.mapText}}></p>
        <div className="map-wrapper">
          <SvgMap className="map-svg"/>
          <ul className="map-list">
            <li className="list-item">
              <SvgLi0 />
              <div className="item-text">
                <span className="number">34</span>
                <p className="info"><strong>wieloprofilowe</strong><br></br> oddziały enel-med</p>
              </div>
            </li>
            <li className="list-item">
              <SvgLi1 />
              <div className="item-text">
                <span className="number">21</span>
                <p className="info">klinik stomatologicznych <br></br> <strong>enel-med stomatologia</strong></p>
              </div>
            </li>
            <li className="list-item">
              <SvgLi2 />
              <div className="item-text">
                <span className="number">3</span>
                <p className="info"><strong>klinik ortopedii</strong>, rehabilitacji<br></br> i medycyny sportowej</p>
              </div>
            </li>
            <li className="list-item">
              <SvgLi3 />
              <div className="item-text">
                <span className="number">3</span>
                <p className="info"><strong>klinik medycyny estetycznej</strong>, rehabilitacji<br></br> ESTELL w Warszawie</p>
              </div>
            </li>
            <li className="list-item">
              <SvgLi4 />
              <div className="item-text">
                <span className="number">1</span>
                <p className="info"><strong>szpital</strong> enel-med</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}


export default Map
