import React from "react"
import JSONData from "../../../../content/healthcare.json"
import './style.scss';
import { StaticImage } from "gatsby-plugin-image"



const Rating = () => {
  return (
    <section className="lp-rating bg-decoration bg-reverse" >
      <div className="container">
        <h2 className="decored" dangerouslySetInnerHTML={{ __html: JSONData.ratingHeading }}></h2>
        <div className="rate-box">

          <h3 className="rate-box__title" dangerouslySetInnerHTML={{ __html: JSONData.ratingBoxHeading }}></h3>
          <p className="rate-box__score" dangerouslySetInnerHTML={{ __html: JSONData.ratingScore }}></p>
          <div className="rate-box__stars">

            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_full.svg"
              width={35}
              height={35}
            />
            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_full.svg"
              width={35}
              height={35}
            />
            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_full.svg"
              width={35}
              height={35}
            />
            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_full.svg"
              width={35}
              height={35}
            />
            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_full.svg"
              width={35}
              height={35}
            />
            <StaticImage
              className="item-star"
              alt="star"

              src="../../../images/lp-healtcare-v2/star_half.svg"
              width={35}
              height={35}
            />

          </div>

          <p className="rate-box__info" dangerouslySetInnerHTML={{ __html: JSONData.ratingInfo }}></p>

          <StaticImage
            className="image-person"
            alt="docktor person"

            src="../../../images/lp-healtcare-v2/person-rating.png"
          />
        </div>
      </div>
    </section>
  )
}


export default Rating
