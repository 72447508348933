import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"

const LpNav = () => {
  return (
    <nav className="lp-nav" aria-label="Menu">
      <div className="lp-nav-wrapper">

        <StaticImage
          className="lp-nav-logo"
          alt="people"
          loading="lazy"
          src="../../../images/lp-healtcare-v2/logo.png"
        />
        <ul className="nav-list">
          <li className="nav-item">
            <a className="nav-link scroll-to" aria-current="page" href="#dlaczego-enelmed">Dlaczego enel-med?</a>
          </li>
          <li className="nav-item">
            <a className="nav-link scroll-to" href="#pakiety">Pakiety</a>
          </li>
          <li className="nav-item">
            <a className="nav-link scroll-to" href="#faq">FAQ</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default LpNav